export function getFirstAvailableNumber(exclude: number[]): number {
  let availableNumber = 1

  while (exclude.includes(availableNumber)) {
    availableNumber++
  }

  return availableNumber
}

export function sumArray(numbers: number[]) {
  return numbers.reduce((acc, currentValue) => acc + currentValue, 0)
}
